<template>
    <div style="direction:rtl">
  <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary">
        <div class="container">
            <!-- Title -->
            <!-- Section title -->
            <div class="row mb-5 justify-content-center text-center">
                <div class="col-lg-6">
                    <span class="badge badge-soft-success badge-pill badge-lg">
                        בראשות מורנו הגאון הרב פינחס שקד שליט"א


                    </span>
                    <h2 class=" mt-4">                        מדרשה לנשים ונערות

                        גבעת האירוסים - נתניה


                    </h2>
                    <div class="mt-2">
                        <p class="lead lh-180">
המדרשה                        </p>
                       

רחוב רותם 129 "גבעת האירוסים" - נתניה

                    </div>
                </div>
            </div>
      <!-- gallery -->
        <div class="container">
          <div>
            <ul>
              <li
                v-for="(image, index) in media"
                :key="index"
                style="display: inline-block"
              >
                <img
                  v-lazy="image.src || image.thumb"
                  class="galley"
                  @click="openGallery(index)"
                />
              </li>
            </ul>

            <LightBox
              ref="lightbox"
              :media="media"
              :show-caption="true"
              :show-light-box="false"
              
            />
          </div>
        </div>
        </div>
</section>
 </div>
</template>
<script>
import LightBox from "vue-image-lightbox";

export default {
  data() {
    return {
      media: [
        {
          thumb: require("../assets/images/midrasha/Image1.jpg"),
          src: require("../assets/images/midrasha/Image1.jpg"),
        },
        {
          thumb: require("../assets/images/midrasha/Image2.jpg"),
          src: require("../assets/images/midrasha/Image2.jpg"),
        },
        {
          thumb: require("../assets/images/midrasha/Image3.jpg"),
          src: require("../assets/images/midrasha/Image3.jpg"),
        },
         {
          thumb: require("../assets/images/midrasha/Image4.jpg"),
          src: require("../assets/images/midrasha/Image4.jpg"),
        },
        {
          thumb: require("../assets/images/midrasha/Image5.jpg"),
          src: require("../assets/images/midrasha/Image5.jpg"),
        },
       
      ],
    };
  },
  components: {
    LightBox,
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>
