<template>
  <div style="direction: rtl">
    <section class="slice py-7">
      <div class="container" style="text-align: right">
        <div class="row row-grid align-items-center" v-if="currentMovie.length>0">
          <div class="col-12 col-md-5 col-lg-6 order-md-2 text-center">
            <!-- video -->
        
            <div class="container" >
           
            <div  >
              <div class="embed-responsive embed-responsive-4by3">
                <iframe
                  class="embed-responsive-item"
                  :src=getMovieUrl(currentMovie[0].videoID)
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                             

              </div>
               <p class="bold">{{ currentMovie[0].name }}</p>
            </div>
     
              
            </div>
          </div>
          <div
            class="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5 text-lg-right"
          >
            <!-- Heading -->
            <h1 class="display-4 mb-3">
                <strong class="text-primary">השיעור השבועי</strong>
            </h1>
            <!-- Text -->
            <p class="lead text-muted">
              {{ currentMovie[0].desc }}
            </p>
            <!-- Buttons -->
            <div class="mt-5">
              <a href="https://www.youtube.com/channel/UCUOouQEqC_a8ZXSpxrWuJ-w/videos" class="btn btn-neutral btn-icon " target="_blank">
                            <span class="btn-inner--text">לערוץ ה YouTube </span>
                           <youtube-icon ></youtube-icon> 
                        </a>

                            <router-link class="btn btn-primary btn-icon" to="/joinus">
                                <span class="btn-inner--text">שותפים לתורה</span>
                                <chevron-left-icon ></chevron-left-icon> 
                                </router-link>
            </div>
          </div>
        </div>
        
      </div>
    </section>
    <section class="slice slice-lg bg-section-secondary">
        <div class="container text-center">
            <div class="row justify-content-center mb-6" style="margin-bottom:10px !important">
                <div class="col-lg-8">
                    <!-- Title -->
                    <h2 class="h1 strong-600">
שיעורים קודמים            </h2>
                    <!-- Text -->
                    <p class="text-muted">
  </p>
                </div>
            </div>
            <div class="container">
               
            <ul style="padding-right: 0px;">
              <li
                v-for="(movie, movieIndex) in movies" :key="movieIndex"
                style="display: inline-block;vertical-align: top;"
              >
               <div style="padding:15px;">
                <iframe
                  class="embed-responsive-item" style="height:225px;width:300px"
                  :src=getMovieUrl(movie.videoID)
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                             
<p class="bold" style="width:300px">{{ movie.name }}</p>
              </div>
               
              </li>
            </ul>
           
              
            </div>
        </div>
        </section>
  </div>
</template>

   <script>
  
   import  {moviesCollection,moviesCurrent}  from '../firebase'

export default {
  data() {
    return {
        movies:[],    
        currentMovie:[
            {videoID:"",
            name:"",
            desc:""}
        ]
    }
  },
  firestore: {
      movies: moviesCollection,
      currentMovie: moviesCurrent
  },
  created() {
     // console.log(this.moviesCollection.doc("3BTP5GV2bpyFkTwiWoKw").get())
      
  },
  methods:{
      getMovieUrl(videoID){
          return "https://www.youtube.com/embed/" + videoID
      }

  }
};
</script> 