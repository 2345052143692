<template>
<div>
   


    <div style="width:80%;max-width:500px;border:2px solid cadetblue;padding:20px;margin-right:auto;margin-left:auto">

        <div style="vertical-align:top;text-align:left;margin:15px 0px 15px 0px;min-width:100%">
            <span style="text-align:left; min-width:100%;margin-right:1px;color:#808080">שם:</span><br />
            <input id="ClientName" type="text" maxlength="30" class="TextBox" /><br />
        </div>

        <div >
            <span style="text-align:left; min-width:100%;margin-right:1px;color:#808080">רחוב:</span><br />
            <input id="Street" type="text" maxlength="30" class="TextBox" /><br />
        </div>

        <div style="vertical-align:top;text-align:left;margin:15px 0px 15px 0px;min-width:100%">
            <span style="text-align:left; min-width:100%;margin-right:1px;color:#808080">עיר:</span><br />
            <input id="City" type="text" maxlength="30" class="TextBox" /><br />
        </div>

        <div style="vertical-align:top;text-align:left;margin:15px 0px 15px 0px;min-width:100%">
            <span style="text-align:left; min-width:100%;margin-right:1px;color:#808080">סכום:</span><br />
            <input id="Amount" type="text" maxlength="30" class="TextBox" /><br />
        </div>

        <div id="TokefDiv" style="vertical-align:top;text-align:left;margin:15px 0px 15px 0px;min-width:100%;display:none">
            <span style="text-align:left; min-width:100%;margin-right:1px;color:#808080">תוקף:</span><br />
            <input id="Tokef" type="text" maxlength="30" class="TextBox" /><br />
        </div>

        <iframe id="NedarimFrame" style="width:100%;-webkit-box-sizing:border-box;height:0px;border:1px solid indianred;" scrolling="no" @onload="PostNedarim({'Name':'GetHeight'})" src="about:blank"></iframe>
        <div style="text-align:center;padding:10px 0px;font-family:Assistant,Arial;color:#808080" id="WaitNedarimFrame"><img src="waitnew.gif" style="width:50px;" /><br />מתחבר לשרת תשלומים...</div>

        <div id="OkDiv" style="font-weight:bold;color:#47ba18;padding:5px;display:none;text-align:center">Transaction Done - העסקה בוצעה בהצלחה</div>

        <div id="PayBtDiv" style="margin:20px 0px 15px 0px;text-align:center">
                      <button id="PayBt"  @click="PayBtClick()" class="button" style="cursor:pointer;color:white;background-color:#17a2b8;text-align:center" >ביצוע תשלום</button>

            <div id="ErrorDiv" style="font-weight:bold;color:firebrick;padding:5px"></div>
        </div>
        <div style="text-align:center;padding:10px 0px;font-family:Assistant,Arial;color:#808080;display:none" id="WaitPay"><img src="waitnew.gif" style="width:50px;" /><br />מבצע חיוב, נא להמתין...</div>

        <div id="Result" style="text-align:center" dir="ltr"></div>


    </div>
    <div style="width:80%;max-width:500px;padding:20px;margin-right:auto;margin-left:auto;font-size:small;text-align:center">
        <div style="vertical-align:top;text-align:left;margin:15px 0px 15px 0px;width:40%;display:inline-block">
            <span style="text-align:left; min-width:100%;margin-right:1px;color:#808080">MosadId:</span><br />
            <input id="MosadId" type="text" maxlength="30" class="TextBox" style="font-size:small" value="0" /><br />
        </div>
    </div>

</div>
</template>
  <style>

        .TextBox {
            -webkit-appearance: none;
            font-family: 'Assistant', sans-serif;
            font-size: large;
            color: black;
            width: 100%;
            text-align: left;
            padding: 6px;
            border: 1px solid rgba(0,0,0,.125);
            border-radius: 2px;
            box-sizing: content-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            margin-top: 2px;
            outline: none;
        }
    </style>
    <script>
        
export default {
  components: {
    
  },mounted() {
      window.onerror = function (msg, url, line, col, error) {
            alert("שגיאת תוכנה. פנה לתמיכה טכנית. שגיאה: " + msg)
        }
       if (window.addEventListener) { window.addEventListener("message", this.ReadPostMessage, false); } else { window.attachEvent("onmessage", this.ReadPostMessage); }
   
        var v = this;
            setTimeout(function () {
               //  alert("create(1)");
                    document.getElementById('NedarimFrame').src = "https://matara.pro/nedarimplus/iframe"; 
                    setTimeout(function () {
                            document.getElementById('NedarimFrame').style.height = "300px";
                                document.getElementById('WaitNedarimFrame').style.display = 'none';
                         }, 500);
                    
            }, 500);
   },
methods: {
    func1(){
        document.getElementById('NedarimFrame').src = 'https://matara.pro/nedarimplus/iframe?language=en&Tokef=Hide';
        document.getElementById('TokefDiv').style.display = 'block';
    },
    func2(){
        document.getElementById('NedarimFrame').src = 'https://matara.pro/nedarimplus/iframe?language=en&Picture=Hide';
        document.getElementById('TokefDiv').style.display = 'none';
    },
    func3(){
        document.getElementById('NedarimFrame').src = 'https://matara.pro/nedarimplus/iframe';
        document.getElementById('TokefDiv').style.display = 'none';
    },
         PostNedarim (Data) { 
             var iframeWin = document.getElementById('NedarimFrame').contentWindow; 
             iframeWin.postMessage(Data, "*"); 
        },
         PayBtClick() {
            document.getElementById('Result').innerHTML = ''
            document.getElementById('PayBtDiv').style.display = 'none';
            document.getElementById('OkDiv').style.display = 'none';
            document.getElementById('WaitPay').style.display = 'block';
            document.getElementById('ErrorDiv').innerHTML = '';
            this.PostNedarim({
                'Name': 'FinishTransaction2',
                'Value': {
                    'Mosad': document.getElementById('MosadId').value,
                    'ApiValid': document.getElementById('ApiValid').value,
                    'PaymentType': 'Ragil',
                    'Currency': '1',

                    'Zeout': '',
                    'FirstName': document.getElementById('ClientName').value,
                    'LastName': '',
                    'Street': document.getElementById('Street').value,
                    'City': document.getElementById('City').value,
                    'Phone': '',
                    'Mail': '',

                    'Amount': document.getElementById('Amount').value,
                    'Tashlumim': '1',

                    'Groupe': '',
                    'Comment': 'בדיקת אייפרם 2',

                    'CallBack': '',

                    'Tokef': document.getElementById('Tokef').value //אם אתם מנהלים את התוקף בדף שלכם (מיועד למי שרוצה להפריד בין חודש לשנה ורוצה לעצב מותאם אישית)

                }
            });
        },
        ReadPostMessage(event) {
           //     alert("event.data.Name" + event.data.Name);

            switch (event.data.Name) {
                case 'Height':
                    //Here you get the height of iframe | event.data.Value
                    document.getElementById('NedarimFrame').style.height = (parseInt(event.data.Value) + 15) + "px";
                    document.getElementById('WaitNedarimFrame').style.display = 'none';
                    break;

                case 'TransactionResponse':
                    document.getElementById('Result').innerHTML = '<b>TransactionResponse:<br/>' + JSON.stringify(event.data.Value) + '</b><br/>see full data in console';
                    console.log(event.data.Value)
                    if (event.data.Value.Status == 'Error') {
                        document.getElementById('ErrorDiv').innerHTML = event.data.Value.Message
                        document.getElementById('WaitPay').style.display = 'none';
                        document.getElementById('PayBtDiv').style.display = 'block';
                    } else {
                        document.getElementById('WaitPay').style.display = 'none';
                        document.getElementById('OkDiv').style.display = 'block';
                    }
            }
        }
        
}
}
    </script>