<template>
  <div style="direction: rtl">
    <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary">
      <div class="container">
        <!-- Title -->
        <!-- Section title -->
        <div class="row mb-5 justify-content-center text-center">
          <div class="col-lg-6">
            <span class="badge badge-soft-success badge-pill badge-lg">
              בראשות מורנו הגאון הרב פינחס שקד שליט"א
            </span>
            <h2 class="mt-4">בית הכנסת המרכזי גבעת האירוסים - נתניה</h2>
            <div class="mt-2">
              <p class="lead lh-180">
                בית הכנסת המרכזי של הקהילה, שיעורי תורה והדרכות.
              </p>

              רחוב רותם 129 "גבעת האירוסים" - נתניה
            </div>
          </div>
        </div>
        <!-- gallery -->
        <div class="container">
          <div>
            <ul>
              <li
                v-for="(image, index) in media"
                :key="index"
                style="display: inline-block"
              >
                <img
                  v-lazy="image.src || image.thumb"
                  class="galley"
                  @click="openGallery(index)"
                />
              </li>
            </ul>

            <LightBox
              ref="lightbox"
              :media="media"
              :show-caption="true"
              :show-light-box="false"
              
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import LightBox from "vue-image-lightbox";

export default {
  data() {
    return {
      media: [
        {
          // For image
          thumb: require("../assets/images/synagogue/Image1.jpg"),
          src: require("../assets/images/synagogue/Image1.jpg"),
          caption: "caption to display.", // Optional
        },
        {
          // For image
          thumb: require("../assets/images/synagogue/Image2.jpg"),
          src: require("../assets/images/synagogue/Image2.jpg"),
          caption: "caption to display", // Optional
        },
        {
          // For image
          thumb: require("../assets/images/synagogue/Image3.jpg"),
          src: require("../assets/images/synagogue/Image3.jpg"),
          caption: "caption to display", // Optional
        }
      ],
    };
  },
  components: {
    LightBox,
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>
