<template>
  <div>
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center min-vh-100">
        <div class="col-md-6 col-lg-5 col-xl-5 py-6 py-md-0">
          <div class="card shadow zindex-100 mb-0" style="text-align: right">
            <div class="card-body px-md-5 py-5" v-if="showPayment">
              <div class="mb-5">
                <h6 class="h3">{{ title }}</h6>
                <p class="text-muted mb-0">שותפים לתורה</p>
              </div>
              <span class="clearfix"></span>
              <form>
                <div class="form-group" id="commentForm" v-if="showComments">
                  <label class="form-control-label">{{ commentHeader }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><user-icon></user-icon>
                      </span>
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      id="commentInput"
                      :placeholder="commentPlaceholder"
                    />
                  </div>
                </div>
                <div class="form-group" id="commentForm" v-if="showComments">
                  <label class="form-control-label">תאריך ההקדשה</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><calendar-icon></calendar-icon>
                      </span>
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      id="commentInput2"
                      placeholder="יש לכתוב תאריך עברי (למשל ג תשרי)"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">שם התורם</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><user-icon></user-icon>
                      </span>
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      id="ClientName"
                      placeholder="פרטי ומשפחה"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">טלפון נייד</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><phone-icon></phone-icon>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="Phone"
                      placeholder="טלפון"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">כתובת מייל</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><mail-icon></mail-icon>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="Mail"
                      placeholder="לא חובה - רק כדי לקבל אישור למייל"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">כתובת</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><map-icon></map-icon>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="Street"
                      placeholder="רחוב ומספר בית - לא חובה"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">עיר</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><home-icon></home-icon>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="City"
                      placeholder="עיר - לא חובה"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">{{ amountTitle }}</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><award-icon></award-icon>
                      </span>
                    </div>
                    <input :disabled="disabled"
                      type="text"
                      class="form-control"
                      id="Amount"
                      placeholder="סכום תרומה בש״ח"
                      :value="amount"
                    />
                  </div>
                </div>
                <div
                  id="TokefDiv"
                  style="
                    vertical-align: top;
                    text-align: left;
                    margin: 15px 0px 15px 0px;
                    min-width: 100%;
                    display: none;
                  "
                >
                  <span
                    style="
                      text-align: left;
                      min-width: 100%;
                      margin-right: 1px;
                      color: #808080;
                    "
                    >תוקף:</span
                  ><br />
                  <input
                    id="Tokef"
                    type="text"
                    maxlength="30"
                    class="TextBox"
                  /><br />
                </div>
                <iframe
                  id="NedarimFrame"
                  style="width: 100%; height: 0px"
                  scrolling="no"
                  @onload="PostNedarim({ Name: 'GetHeight' })"
                  src="about:blank"
                ></iframe>
                <div
                  style="
                    text-align: center;
                    padding: 10px 0px;
                    font-family: Assistant, Arial;
                    color: #808080;
                  "
                  id="WaitNedarimFrame"
                >
                  <img src="waitnew.gif" style="width: 50px" /><br />מתחבר לשרת
                  תשלומים...
                </div>

                <div id="PayBtDiv" class="mt-4">
                  <button
                    type="button"
                    id="PayBt"
                    @click="PayBtClick()"
                    class="btn btn-block btn-primary"
                  >
                    ביצוע תשלום
                  </button>
                  <div
                    id="ErrorDiv"
                    style="font-weight: bold; color: firebrick; padding: 5px"
                  ></div>
                </div>
                <div
                  style="
                    text-align: center;
                    padding: 10px 0px;
                    font-family: Assistant, Arial;
                    color: #808080;
                    display: none;
                  "
                  id="WaitPay"
                >
                  <img src="waitnew.gif" style="width: 50px" /><br />מבצע חיוב,
                  נא להמתין...
                </div>
                <div id="Result" style="text-align: center" dir="ltr"></div>
              </form>
            </div>

                <div id="OkDiv" style="font-weight: bold;padding: 5px;display: none;text-align: center;">
                  <h6 class="h3">תודה על תרומתכם!</h6>
                  אם יש לכם שאלות נשמח לסייע בטלפון 09-7725777 או  <a target="_blank" href="https://wa.me/97298335994" >בוואטסאפ</a> 
                </div>

            <div class="card-footer px-md-5">
              <small>צריך עזרה?</small>
              <a href="contactus" class="small font-weight-bold">צור קשר</a> או <a target="_blank" href="https://wa.me/97298335994" >בוואטסאפ</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <style>
.TextBox {
  -webkit-appearance: none;
  font-family: "Assistant", sans-serif;
  font-size: large;
  color: black;
  width: 100%;
  text-align: left;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
  box-sizing: content-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-top: 2px;
  outline: none;
}
</style>
    <script>
export default {
  data() {
    return {
      amount: '',
      paymentType: "Ragil",
      tashlumim: 1,
      title: "תרומה",
      showComments: true,
      commentHeader: "הערות",
      commentPlaceholder: "ניתן להקדיש את התרומה",
      amountTitle: "סכום התרומה",
      disabled: false,
      showPayment: true
    };
  },
  components: {},
  mounted() {
    window.onerror = function (msg, url, line, col, error) {
      alert("שגיאת תוכנה. פנה לתמיכה טכנית. שגיאה: " + msg);
    };
    if (window.addEventListener) {
      window.addEventListener("message", this.ReadPostMessage, false);
    } else {
      window.attachEvent("onmessage", this.ReadPostMessage);
    }

    var v = this;
    setTimeout(function () {
      document.getElementById("NedarimFrame").src =
        "https://matara.pro/nedarimplus/iframe";

      setTimeout(function () {
        document.getElementById("NedarimFrame").style.height = "300px";
        document.getElementById("WaitNedarimFrame").style.display = "none";
      }, 500);
    }, 500);
  },
  created() {
    this.getPaymentData();
  },
  methods: {
    getPaymentData() {
      switch (this.$route.query.p) {
        case "0":
          this.paymentType = "Ragil";
          this.tashlumim = 1;
          this.amount = '';
          this.title = "תרומה";
          this.commentHeader = "הערות";
          this.commentPlaceholder = "ניתן להקדיש את התרומה";
          this.showComments = false;
          this.amountTitle = "סכום התרומה - חד פעמי";
          this.disabled=false;
          break;
        case "1":
          this.paymentType = "Ragil";
          this.tashlumim = 1;
          this.amount = 700;
          this.title = "ניצב יום - הקדשת לימוד בישיבה";
          this.commentHeader = "הקדשה עבור";
          this.commentPlaceholder = "שם ושם האב/אם וסיבה (לרפואה וכו)";
          this.amountTitle = "סכום התרומה - חד פעמי";

          this.showComments = true;
this.disabled=true;
          break;
        case "2":
          this.paymentType = "HK";
          this.tashlumim = 12;
          this.amount = 600;
          this.title = "אמץ תלמיד - הסכם זבולון ויששכר";
          this.showComments = false;
          this.amountTitle = "סכום התרומה - חודשי";
this.disabled=true;
          break;
        case "3":
          this.paymentType = "Ragil";
          this.tashlumim = 1;
          this.amount = 1000;
          this.title = "הקדשת סעודת יום אחד בישיבה";

          this.commentHeader = "הקדשה עבור";
          this.commentPlaceholder = "שם ושם האב/אם וסיבה (חתונה וכו)";
          this.showComments = true;
          this.amountTitle = "סכום התרומה - חד פעמי";
this.disabled=true;
          break;
        default:
          this.paymentType = "Ragil";
          this.tashlumim = 1;
          this.amount = '';
          this.title = "תרומה";
          this.commentHeader = "הערות";
          this.commentPlaceholder = "ניתן להקדיש את התרומה";
          this.showComments = true;
          this.disabled=false;
      }
    },
    func1() {
      document.getElementById("NedarimFrame").src =
        "https://matara.pro/nedarimplus/iframe?language=en&Tokef=Hide";
      document.getElementById("TokefDiv").style.display = "block";
    },
    func2() {
      document.getElementById("NedarimFrame").src =
        "https://matara.pro/nedarimplus/iframe?language=en&Picture=Hide";
      document.getElementById("TokefDiv").style.display = "none";
    },
    func3() {
      document.getElementById("NedarimFrame").src =
        "https://matara.pro/nedarimplus/iframe";
      document.getElementById("TokefDiv").style.display = "none";
    },
    PostNedarim(Data) {
      var iframeWin = document.getElementById("NedarimFrame").contentWindow;
      iframeWin.postMessage(Data, "*");
    },
    PayBtClick() {
      document.getElementById("Result").innerHTML = "";
      document.getElementById("PayBtDiv").style.display = "none";
      document.getElementById("OkDiv").style.display = "none";
      document.getElementById("WaitPay").style.display = "block";
      document.getElementById("ErrorDiv").innerHTML = "";
      this.PostNedarim({
        Name: "FinishTransaction2",
        Value: {
          Mosad: "7001099", //test:"0", //document.getElementById('MosadId').value,
          ApiValid: "RMLz+j6hik", //test: "j+iyEFN3bE", // document.getElementById('ApiValid').value,
          PaymentType: this.paymentType,
          Currency: "1",

          Zeout: "",
          FirstName: document.getElementById("ClientName").value,
          LastName: "",
          Street: document.getElementById("Street").value,
          City: document.getElementById("City").value,
          Phone: document.getElementById("Phone").value,
          Mail: document.getElementById("Mail").value,

          Amount: document.getElementById("Amount").value,
          Tashlumim: this.tashlumim,

          Groupe: "",
          Comment: document.getElementById("commentInput").value + " " + document.getElementById("commentInput2").value,

          CallBack: "",

          Tokef: document.getElementById("Tokef").value, //אם אתם מנהלים את התוקף בדף שלכם (מיועד למי שרוצה להפריד בין חודש לשנה ורוצה לעצב מותאם אישית)
        },
      });
    },
    ReadPostMessage(event) {
      //     alert("event.data.Name" + event.data.Name);

      switch (event.data.Name) {
        case "Height":
          //Here you get the height of iframe | event.data.Value
          document.getElementById("NedarimFrame").style.height =
            parseInt(event.data.Value) + 15 + "px";
          document.getElementById("WaitNedarimFrame").style.display = "none";
          break;

        case "TransactionResponse":
          // document.getElementById("Result").innerHTML =
          //   "<b>TransactionResponse:<br/>" +
          //   JSON.stringify(event.data.Value) +
          //   "</b><br/>see full data in console";
          console.log(event.data.Value);
          if (event.data.Value.Status == "Error") {
            document.getElementById("ErrorDiv").innerHTML =
              event.data.Value.Message;
            document.getElementById("WaitPay").style.display = "none";
            document.getElementById("PayBtDiv").style.display = "block";
          } else {
            document.getElementById("WaitPay").style.display = "none";
            document.getElementById("OkDiv").style.display = "block";
            this.showPayment=false;
          }
      }
    },
  },
};
</script>