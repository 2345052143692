 <template>
  <div style="direction:rtl">
      <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary">
        <div class="container">
            <!-- Title -->
            <!-- Section title -->
            <div class="row mb-5 justify-content-center text-center">
                <div class="col-lg-6">
                    <span class="badge badge-soft-success badge-pill badge-lg">
                        בראשות מורנו הגאון הרב פינחס שקד שליט"א


                    </span>
                    <h2 class=" mt-4">                        ישיבה לצעירים מצוינים "עטרת מתתיהו"
                    </h2>
                    <div class="mt-2">
                        <p class="lead lh-180">הישיבה מונה כ 60 בחורים מצויינים המגיעים אליה מכל חלקי העיר נתניה וכן מהישובים סביב ומחדרה.</p>
                        <p class="lead lh-180">    
                        ✔️ כהוראתם של גדולי ומאורי הדור שליט"א , בישיבה קיים מערך הסעות יומי והבחורים חוזרים יום יום לביתם.
                    </p>
                        <p class="lead lh-180">
                            ✔️ בישיבה קיים  מסגרת של "מכינה לישיבה" המתאימה לתלמידים בכיתות ח' המעוניינים להשתלב בשנת מכינה לקראת הגעתם לישיבה. 
                        </p>
                            <p class="lead lh-180">
                            ✔️ בסיום שלש שנות לימוד בישיבה זוכים הבחורים להכנס לישיבות הגדולות הטובות והחשובות בארץ. 
                            </p>

                            כתובת הישיבה רח' זלמן שניאור 23 - נתניה טלפקס 09-7725777
או 09-8335994
                    </div>
                </div>
            </div>
          <!-- gallery -->
        <div class="container">
          <div>
            <ul>
              <li
                v-for="(image, index) in media"
                :key="index"
                style="display: inline-block"
              >
                <img
                  v-lazy="image.src || image.thumb"
                  class="galley"
                  @click="openGallery(index)"
                />
              </li>
            </ul>

            <LightBox
              ref="lightbox"
              :media="media"
              :show-caption="true"
              :show-light-box="false"
              
            />
          </div>
        </div>
        </div>
</section>
 </div>
</template>
<script>
import LightBox from "vue-image-lightbox";

export default {
  data() {
    return {
      media: [
        {
          thumb: require("../assets/images/yeshiva/Image1.jpg"),
          src: require("../assets/images/yeshiva/Image1.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image2.jpg"),
          src: require("../assets/images/yeshiva/Image2.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image3.jpg"),
          src: require("../assets/images/yeshiva/Image3.jpg"),
        },
         {
          thumb: require("../assets/images/yeshiva/Image4.jpg"),
          src: require("../assets/images/yeshiva/Image4.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image5.jpg"),
          src: require("../assets/images/yeshiva/Image5.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image6.jpg"),
          src: require("../assets/images/yeshiva/Image6.jpg"),
        },
         {
          thumb: require("../assets/images/yeshiva/Image7.jpg"),
          src: require("../assets/images/yeshiva/Image7.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image8.jpg"),
          src: require("../assets/images/yeshiva/Image8.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image9.jpg"),
          src: require("../assets/images/yeshiva/Image9.jpg"),
        },
         {
          thumb: require("../assets/images/yeshiva/Image10.jpg"),
          src: require("../assets/images/yeshiva/Image10.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image11.jpg"),
          src: require("../assets/images/yeshiva/Image11.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image12.jpg"),
          src: require("../assets/images/yeshiva/Image12.jpg"),
        },
         {
          thumb: require("../assets/images/yeshiva/Image13.jpg"),
          src: require("../assets/images/yeshiva/Image13.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image14.jpg"),
          src: require("../assets/images/yeshiva/Image14.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image15.jpg"),
          src: require("../assets/images/yeshiva/Image15.jpg"),
        },
         {
          thumb: require("../assets/images/yeshiva/Image16.jpg"),
          src: require("../assets/images/yeshiva/Image16.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image17.jpg"),
          src: require("../assets/images/yeshiva/Image17.jpg"),
        },
        {
          thumb: require("../assets/images/yeshiva/Image18.jpg"),
          src: require("../assets/images/yeshiva/Image18.jpg"),
        },
      ],
    };
  },
  components: {
    LightBox,
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>
