<template>
    <div style="direction:rtl">
  <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary">
        <div class="container">
            <!-- Title -->
            <!-- Section title -->
            <div class="row mb-5 justify-content-center text-center">
                <div class="col-lg-6">
                    <span class="badge badge-soft-success badge-pill badge-lg">
                        בראשות מורנו הגאון הרב פינחס שקד שליט"א


                    </span>
                    <h2 class=" mt-4">                        תלמוד תורה "מרכז החינוך העצמאי" - "אורחות יצחק"

                    </h2>
                    <div class="mt-2">
                        <p class="lead lh-180">
                            שוכן  על גבעה פסטורלית ומיוחדת באווירה ביתית וחמימה 
                        </p>
                        <p class="lead lh-180">    
                            ומונה כ 140 תלמידים מכיתות א'- ז'
                        </p>
                        <p class="lead lh-180">
                            בת"ת שמים דגש רב על חינוך וערכים מוסר ומידות , והכל בחום הבנה וחיוך.
                        </p>
                            <p class="lead lh-180">
                                כמו"כ מספר התלמידים בכל כיתה לא עולה על 20 תלמידים, בכדי שכל תלמיד יוכל לקבל את מירב תשומת הלב כדי לנצל את יכולותיו המקסימלים.  
                            </p>

                            ברחוב עולי הגרדום 27 - נתניה

                    </div>
                </div>
            </div>
       <!-- gallery -->
        <div class="container">
          <div>
            <ul>
              <li
                v-for="(image, index) in media"
                :key="index"
                style="display: inline-block"
              >
                <img
                  v-lazy="image.src || image.thumb"
                  class="galley"
                  @click="openGallery(index)"
                />
              </li>
            </ul>

            <LightBox
              ref="lightbox"
              :media="media"
              :show-caption="true"
              :show-light-box="false"
              
            />
          </div>
        </div>
        </div>
</section>
 </div>
</template>
<script>
import LightBox from "vue-image-lightbox";

export default {
  data() {
    return {
      media: [
        {
          thumb: require("../assets/images/talmod/Image1.jpg"),
          src: require("../assets/images/talmod/Image1.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image2.jpg"),
          src: require("../assets/images/talmod/Image2.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image3.jpg"),
          src: require("../assets/images/talmod/Image3.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image4.jpg"),
          src: require("../assets/images/talmod/Image4.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image5.jpg"),
          src: require("../assets/images/talmod/Image5.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image6.jpg"),
          src: require("../assets/images/talmod/Image6.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image7.jpg"),
          src: require("../assets/images/talmod/Image7.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image8.jpg"),
          src: require("../assets/images/talmod/Image8.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image9.jpg"),
          src: require("../assets/images/talmod/Image9.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image10.jpg"),
          src: require("../assets/images/talmod/Image10.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image11.jpg"),
          src: require("../assets/images/talmod/Image11.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image12.jpg"),
          src: require("../assets/images/talmod/Image12.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image13.jpg"),
          src: require("../assets/images/talmod/Image13.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image14.jpg"),
          src: require("../assets/images/talmod/Image14.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image15.jpg"),
          src: require("../assets/images/talmod/Image15.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image16.jpg"),
          src: require("../assets/images/talmod/Image16.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image17.jpg"),
          src: require("../assets/images/talmod/Image17.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image18.jpg"),
          src: require("../assets/images/talmod/Image18.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image19.jpg"),
          src: require("../assets/images/talmod/Image19.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image20.jpg"),
          src: require("../assets/images/talmod/Image20.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image21.jpg"),
          src: require("../assets/images/talmod/Image21.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image22.jpg"),
          src: require("../assets/images/talmod/Image22.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image23.jpg"),
          src: require("../assets/images/talmod/Image23.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image24.jpg"),
          src: require("../assets/images/talmod/Image24.jpg"),
        },
        {
          thumb: require("../assets/images/talmod/Image25.jpg"),
          src: require("../assets/images/talmod/Image25.jpg"),
        },
         {
          thumb: require("../assets/images/talmod/Image26.jpg"),
          src: require("../assets/images/talmod/Image26.jpg"),
        },
      ],
    };
  },
  components: {
    LightBox,
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>
